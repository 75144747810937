// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/num.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".item_images[data-v-9c8c38f0]{letter-spacing:.104167rem;display:flex;line-height:2.083333rem}.item_images .image_on[data-v-9c8c38f0]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;width:3.125rem;height:2.083333rem;background-position:0 .364583rem}.item_images .images_t[data-v-9c8c38f0]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;width:3.125rem;height:2.083333rem;background-position:0 -1.5625rem}.item_images .images_s[data-v-9c8c38f0]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;width:3.125rem;height:2.083333rem;background-position:0 -3.333333rem}.item_images .images_n[data-v-9c8c38f0]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;width:3.125rem;height:2.083333rem;background-position:0 -5.208333rem}.item_images .images_o[data-v-9c8c38f0]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;width:3.125rem;height:2.083333rem;background-position:0 -7.083333rem}.item_images .images_p[data-v-9c8c38f0]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;width:3.125rem;height:2.083333rem;background-position:0 -8.958333rem}.item_images .images_m[data-v-9c8c38f0]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;width:3.125rem;height:2.083333rem;background-position:0 -10.677083rem}.title[data-v-9c8c38f0]{display:flex;justify-content:center;margin-bottom:1.979167rem}.title[data-v-9c8c38f0] :first-child{font-size:1.041667rem}.title[data-v-9c8c38f0] :nth-child(2){font-size:.729167rem;color:#00f;margin-left:.520833rem;margin-top:.260417rem}.regul[data-v-9c8c38f0]{width:100%;display:flex;border:.052083rem solid #fad8c3;font-size:.833333rem;line-height:1.5625rem;padding-left:2.604167rem;padding-top:1.927083rem;padding-bottom:1.5625rem}", ""]);
// Exports
module.exports = exports;
