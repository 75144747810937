<template>
  <div class="particu">
    <div class="particu_name bto_bor">
      <div>
        <div class="title">{{ pageData.publisherName }}</div>
        <div class="number">
          联系方式： <span>{{ pageData.contacts }}</span>
          <span style="margin-left: 20px; width: 100px">{{
            pageData.contactInfo
          }}</span>
        </div>
      </div>

      <div>
        <div style="text-align: left">
          <div style="font-size: 20px; font-weight: bold">
            商品编号： {{ pageData.goodsNum }}
          </div>
        </div>
      </div>
    </div>
    <!-- 竞价 -->
    <div class="auction">
      <div class="auction_item">
        <div v-if="pageData.lineName">
          <span style="display: inline-block; width: 100px">线路名称：</span>
          <span>{{ pageData.lineName }}</span>
        </div>
      </div>
      <div class="auction_item">
        <div style="display: flex">
          <div style="display: flex">
            <div style="display: inline-block; width: 100px">货物名称：</div>
            <div>{{ pageData.goodsName }}</div>
          </div>
          <div>
            <span style="display: inline-block; margin-left: 20px"
              >竞价限价({{ pageData.taxIncluded | taxIncluded }})：</span
            >
            <span style="color: red">{{ pageData.floorPrice }}元/吨</span>
          </div>
          <div>
            <span style="display: inline-block; margin-left: 20px"
              >保证金：</span
            >
            <span style="color: red">{{ pageData.bond }}万元</span>
          </div>
          <div>
            <span style="display: inline-block; margin-left: 20px"
              >发运量：</span
            >
            <span style="color: red">{{ pageData.monthFreightVolume }}吨</span>
          </div>
        </div>
      </div>

      <div class="auction_item">
        <div>
          <span style="display: inline-block; width: 100px">支付方式：</span>
          <span>{{ pageData.payType | initPayType }}</span>
        </div>
      </div>
    </div>
    <div class="relation" style="display: flex">
      <div>
        <span style="display: inline-block; width: 100px">线路类型：</span>
        <span>{{ pageData.lineType | lineType }}</span>
      </div>
      <div style="margin-left: 20px">
        <span style="display: inline-block">运距：</span>
        <span>{{ pageData.transportDistance }}公里</span>
      </div>
    </div>
    <div class="remark">
      <div style="width: 100px">出发地：</div>
      <div>{{ pageData.originPlace }}</div>
    </div>
    <div class="remark">
      <div style="width: 100px">目的地：</div>
      <div>{{ pageData.deliveryPlace }}</div>
    </div>
    <div class="remark">
      <div style="width: 100px">竞价时间：</div>
      <div>
        {{ pageData.biddingStartTime | formatCreateTime }} ~
        {{ pageData.biddingEndTime | formatCreateTime }}
      </div>
    </div>
    <div class="remark">
      <div style="width: 100px">服务期限：</div>
      <div>
        {{ pageData.serviceStartTime | formatCreateTime }} ~
        {{ pageData.serviceEndTime | formatCreateTime }}
      </div>
    </div>
    <div class="remark">
      <div style="width: 100px">允许综合途耗：</div>
      <div>{{ pageData.allowloss }} ‰</div>
    </div>

    <div class="remark">
      <div style="width: 100px">备注说明：</div>
      <div>{{ pageData.remark }}</div>
    </div>
  </div>
</template>

<script>
import { getTbGoodsDetail } from "@/api/Logistics.js";
import { orderDetail } from "@/api/public.js";
import { formatDate } from "@/utils/date";
export default {
  data() {
    return {
      pageData: {},
      tradeGoodsListing: {},
      quota: [],
      pageData: null,
      pageData: {},
      selectOrderType: null,
      ifInvite: null,
    };
  },
  components: {
    // CountDown: () => import("@/components/countDowns.vue"),
  },
  filters: {
    formatCreateTime(time) {
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    lineType(val) {
      let arr = ["未知", "短名单模式", "公开模式"];
      return arr[val];
    },
    listingPrice(data) {
      if (data || data == 0) {
        return data == 0 ? "面议" : "￥" + data + "/吨";
      } else {
        return "";
      }
    },
    initPayType(val) {
      let arr = ["未知", "现金", "承兑", "现金+承兑"];
      return arr[val];
    },
    taxIncluded(val) {
      let arr = ["含税", "不含税"];
      return arr[val];
    },
  },
  mounted() {
    console.log(this.statusId);
    this.selectOrderType = this.$route.query.selectOrderType;
    this.ifInvite = this.$route.query.ifInvite;
    console.log(this.$route.query.ifInvite, "哈哈哈哈哈哈哈哈哈哈哈");
    if (this.$route.query.goodsId) {
      this.getlisst(this.$route.query.goodsId);
    } else if (this.$route.query.orderId) {
      this.orderList(this.$route.query.orderId);
    }
  },
  methods: {
    // getstatus(){
    //   let id =  this.$route.query.goodsId;
    //   getEnterpriseStatus(id).then(res=>{
    //     if(res.code==0){
    //       this.enterpData=res.data
    //     }
    //   })
    // },
    getlisst() {
      getTbGoodsDetail(this.$route.query.goodsId).then((res) => {
        if (res.code == 0) {
          this.pageData = res.data;
          // console.log(this.pageData , "99999999999");
          // if (res.data.quotaList) {
          //   let quotas = res.data.quotaList;
          //   quotas.forEach((item) => {
          //     item.name =
          //       item.name +
          //       `(${item.unit.split(";")[0]})` +
          //       `${item.unit.split(";")[2]}`;
          //     item.value = item.value + `${item.unit.split(";")[1]}`;
          //   });
          //   this.quota = quotas;
          // }
          // if (res.data.pageData) {
          //   let newlist = res.data.pageData;
          //   if (newlist.createTime) {
          //     newlist.createTime = newlist.createTime.replace("T", " ");
          //   }
          //   newlist.createTime = newlist.createTime.replace("T", " ");
          //   if (newlist.deliveryStartTime) {
          //     newlist.deliveryStartTime = newlist.deliveryStartTime.replace(
          //       "T",
          //       " "
          //     );
          //   }
          //   if (newlist.deliveryEndTime) {
          //     newlist.deliveryEndTime = newlist.deliveryEndTime.replace(
          //       "T",
          //       " "
          //     );
          //   }
          //   this.pageData = newlist;
          // }
          // if (res.data.pageData) {
          //   let list = res.data.pageData;
          //   for (const key in list) {
          //     if (key == "startTime") {
          //       list[key] = list[key].replace("T", " ");
          //     }
          //     if (key == "endTime") {
          //       list[key] = list[key].replace("T", " ");
          //     }
          //   }
          //   this.pageData = list;
          // }
          // this.$emit("child-event", res.data);
          // if (res.data && res.data.tradeGoodsListing) {
          //   this.tradeGoodsListing = res.data.tradeGoodsListing;
          // } else {
          //   this.tradeGoodsListing = null;
          // }
          // console.log(this.tradeGoodsListing, "this.tradeGoodsListing");
        }
      });
    },
    orderList(id) {
      console.log(id);
      let config = {
        orderId: id,
      };
      orderDetail(config).then((res) => {
        if (res.code == 0) {
          this.pageData = res.data;
          if (res.data.quotas) {
            let quotas = res.data.quotas;
            quotas.forEach((item) => {
              item.name =
                item.name +
                `(${item.unit.split(";")[0]})` +
                `${item.unit.split(";")[2]}`;
              item.value = item.value + `${item.unit.split(";")[1]}`;
            });
            this.quota = quotas;
          }
          if (res.data.pageData) {
            let newlist = res.data.pageData;
            if (newlist.createTime) {
              newlist.createTime = newlist.createTime.replace("T", " ");
            }
            newlist.createTime = newlist.createTime.replace("T", " ");
            if (newlist.deliveryStartTime) {
              newlist.deliveryStartTime = newlist.deliveryStartTime.replace(
                "T",
                " "
              );
            }
            if (newlist.deliveryEndTime) {
              newlist.deliveryEndTime = newlist.deliveryEndTime.replace(
                "T",
                " "
              );
            }
            this.pageData = newlist;
          }
          if (res.data.pageData) {
            let list = res.data.pageData;
            for (const key in list) {
              if (key == "startTime") {
                list[key] = list[key].replace("T", " ");
              }
              if (key == "endTime") {
                list[key] = list[key].replace("T", " ");
              }
            }
            this.pageData = list;
          }
          this.$emit("child-event", res.data);
          if (res.data && res.data.tradeGoodsListing) {
            this.tradeGoodsListing = res.data.tradeGoodsListing;
            console.log(this.tradeGoodsListing, "this.tradeGoodsListing");
          } else {
            this.tradeGoodsListing = null;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item_images {
  width: 100px;
  height: 24px;
  background: url("../../../assets/images/tag.png") no-repeat;
  background-size: 100px 100%;
  color: white;
  padding-left: 3px;
  padding-top: 2px;
  margin-top: 10px;
  display: flex;
}
.particu_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px dashed #ccc;
  border-bottom: 1px dashed #ccc;
  margin-top: 20px;
}
.particu {
  padding: 20px;
  border: 1px solid #ccc;
  .particu_name {
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 18px;
      font-weight: bold;
    }
    .number {
      margin-top: 5px;
      // margin-left: 20px;
    }
  }
  .particu_items {
    display: flex;
    align-content: center;
    // justify-content: space-between;
    // padding: 20px;
    padding: 20px 0;
    // border: 1px solid #ccc;
    // margin-top: 20px;
  }
  .particu_item {
    display: flex;
    // justify-content: space-between;
    // padding: 20px;
    padding: 20px 0;
    // border: 1px solid #ccc;
    border-top: 1px dashed #ccc;
    border-bottom: 1px dashed #ccc;
    margin-top: 20px;
  }
  .auction {
    margin-top: 20px;
    .auction_item {
      // display: flex;
      margin-top: 20px;
      .finish {
        // margin-left: 20px;
        margin-top: 20px;
      }
    }
  }
  .relation {
    margin-top: 20px;
  }
  .delivery {
    margin-top: 20px;
  }
  .indicator {
    margin-top: 20px;
    display: flex;
    .tables {
      // margin-top: 10px;
      text-align: center;
      table {
        border: none;
      }
      .tables_tr {
        // display: flex;
        flex-wrap: wrap;
        font-weight: bold;
      }
      td {
        border: none;
        width: 220px;
        padding: 5px 10px;
        border: 1px dashed black;
      }
    }
  }
  .remark {
    margin-top: 20px;
    display: flex;
  }
}
.bto_bor {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}
</style>
