<template>
  <div>
    <div class="mall">
      <div class="aside">
        <div class="t">产品分类</div>
        <el-scrollbar style="height: calc(100% - 51px)">
          <Left @select="typeSearch" />
        </el-scrollbar>
      </div>
      <div class="content">
        <div class="search">
          <div class="form">
            <el-input
              v-model="searchData.enterpriseName"
              placeholder="请输入公司名称"
              maxLength="99"
            ></el-input>
            <el-cascader
              v-model="searchData.originPlace"
              :options="citys"
              placeholder="请选择产地"
              :props="{
                value: 'label',
                label: 'label',
              }"
            ></el-cascader>
            <el-select
              v-model="searchData.listingStatus"
              v-if="searchData.selectTradeType == 1"
              placeholder="挂牌状态"
              style="width: 300px; margin-left: 10px"
            >
              <el-option
                placeholder="请选择挂牌状态"
                v-for="item in listingStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="searchData.tradeStatus"
              v-if="searchData.selectTradeType == 2"
              placeholder="竞拍状态"
              style="width: 300px; margin-left: 10px"
            >
              <el-option
                placeholder="请选择竞拍状态"
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-button @click="searchFn" class="hy-btn">搜索</el-button>
            <el-button @click="resetFn" class="cl-btn">清除</el-button>
          </div>
        </div>

        <div class="sort-box">
          <div class="left">
            <div class="items" @click="sort('releaseDateOrderBy')">
              <span>发布日期</span>
              <span class="caret-wrapper">
                <i
                  class="el-icon-caret-top"
                  :class="{ active: searchData.releaseDateOrderBy == 1 }"
                ></i>
                <i
                  class="el-icon-caret-bottom"
                  :class="{ active: searchData.releaseDateOrderBy == 0 }"
                ></i>
              </span>
            </div>
            <div class="items" @click="sort('priceOrderBy')">
              <span>价格</span>
              <span class="caret-wrapper">
                <i
                  class="el-icon-caret-top"
                  :class="{ active: searchData.priceOrderBy == 1 }"
                ></i>
                <i
                  class="el-icon-caret-bottom"
                  :class="{ active: searchData.priceOrderBy == 0 }"
                ></i>
              </span>
            </div>
          </div>
          <div class="right">
            <el-button @click="add" class="hy-btn">
              发布{{ $route.query.selectTradeType == 1 ? "挂牌" : "竞价"
              }}{{ $route.query.selectOrderType == 1 ? "销售" : "采购" }}
            </el-button>
          </div>
        </div>
        <div class="list" v-if="tableList.list.length > 0">
          <div
            class="items"
            v-for="item in tableList.list"
            :key="item.goodsId"
            @click="detail(item)"
          >
            <div class="item-title f-hide item_type">
              <!-- <div style="width:300px;text-overflow:ellipsis;white-space: nowrap;overflow: hidden;">{{ item.enterpriseName }}</div> -->
              <el-popover
                placement="top-start"
                title=""
                width="200"
                trigger="hover"
                :content="item.enterpriseName"
              >
                <div
                  slot="reference"
                  style="
                    width: 220px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  "
                >
                  {{ item.enterpriseName }}
                </div>
              </el-popover>
              <div
                style="font-size: 14px; color: #999; font-weight: normal"
                v-if="$route.query.selectTradeType == 2"
              >
                {{ item.goodsStatus | goodsStatus }}
              </div>
            </div>
            <div class="info">
              <div class="info-left">
                <!-- <el-popover
                  placement="top-start"
                  title=""
                  width="200"
                  trigger="hover"
                  :content="item.goodsName"
                  v-if="searchData.selectTradeType != 1"
                  ><p class="f-hide">
                    {{ item.goodsName }}
                  </p>
                  <div
                    slot="reference"
                    style="
                      width: 120px;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                    "
                  >
                    <p class="f-hide">
                      {{ item.goodsName }}
                    </p>
                  </div>
                </el-popover> -->

                <el-popover
                  placement="top-start"
                  title=""
                  width="200"
                  trigger="hover"
                  :content="item.goodsName"
                  ><p>
                    <span
                      v-for="(goodsName, index) in item.goodsCategoryList"
                      :key="index"
                      >{{ goodsName.goodsName }} &nbsp;
                      {{
                        index == item.goodsCategoryList.length - 1 ? "" : "|"
                      }}&nbsp;</span
                    >
                  </p>

                  <div
                    slot="reference"
                    style="
                      width: 120px;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                    "
                  >
                    <p class="f-hide">
                      <span
                        v-for="(goodsName, index) in item.goodsCategoryList"
                        :key="index"
                        >{{ goodsName.goodsName }} &nbsp;
                        {{
                          index == item.goodsCategoryList.length - 1 ? "" : "|"
                        }}&nbsp;</span
                      >
                    </p>
                  </div>
                </el-popover>

                <el-popover
                  placement="top-start"
                  title=""
                  width="200"
                  trigger="hover"
                  :content="item.goodsName"
                >
                  <p v-if="searchData.selectTradeType == 1">
                    <span>{{
                      item.ifConsultRealityCount == 0
                        ? item.stockCount + "吨"
                        : "以实际结算数量为准"
                    }}</span>
                  </p>
                  <p v-if="searchData.selectTradeType != 1">
                    <span>{{
                      item.ifConsultRealityCount == 0
                        ? formatCount(item)
                        : "以实际结算数量为准"
                    }}</span>
                  </p>

                  <div
                    slot="reference"
                    style="
                      width: 120px;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                    "
                  >
                    <p class="f-hide" v-if="searchData.selectTradeType == 1">
                      {{
                        item.ifConsultRealityCount == 0
                          ? item.stockCount + "吨"
                          : "以实际结算数量为准"
                      }}
                    </p>
                    <p class="f-hide" v-if="searchData.selectTradeType != 1">
                      {{
                        item.ifConsultRealityCount == 0
                          ? formatCount(item)
                          : "以实际结算数量为准"
                      }}
                    </p>
                  </div>
                </el-popover>
              </div>
              <div class="price" :class="[+item.price == 0 ? 'no-price' : '']">
                <p v-if="item.distanceStartTime" class="imag_dao">
                  <img
                    src="../../assets/zxx_img/daojishi.png"
                    alt=""
                    style="width: 20px"
                  />
                  <CountDown
                    v-if="item.distanceStartTime"
                    :endDate="`${item.distanceStartTime}`"
                  ></CountDown>
                </p>
                <p v-if="item.endTime" class="imag_dao">
                  <img
                    src="../../assets/zxx_img/daojishi.png"
                    alt=""
                    style="width: 20px"
                  />
                  <CountDown
                    v-if="item.endTime"
                    :endDate="`${item.endTime}`"
                  ></CountDown>
                </p>
                <p style="text-align: right; margin-top: 5px">
                  {{ item.goodsCategoryList | priceCategoryFilter }}
                </p>
              </div>
            </div>
            <div
              class="indicator f-hide2"
              v-if="item.title || item.goodsCategoryList.length"
            >
              <!-- <el-tooltip
                class="item"
                effect="dark"
                :content="item.title"
                placement="top-start"
                v-if="searchData.selectTradeType != 1"
              >
                <span
                  >商品指标：
                  <span class="indicator_span">{{ item.title }}</span></span
                >
              </el-tooltip> -->
              <el-tooltip class="item" effect="dark" placement="top-start">
                <div slot="content">
                  <div
                    v-for="(goods, index) in item.goodsCategoryList"
                    :key="goods.goodsName + index"
                  >
                    <div v-if="goods.quotas.length">
                      <span>{{ goods.goodsName }}:</span>
                      <span
                        v-for="(quota, quotaIndex) in goods.quotas"
                        :key="quota.name + quotaIndex"
                        >{{
                          quota.name +
                          quota.unit.split(";")[2] +
                          quota.value +
                          quota.unit.split(";")[1] +
                          ","
                        }}</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  v-for="(goods, index) in item.goodsCategoryList"
                  :key="goods.goodsName + index"
                  style="
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  <div v-if="goods.quotas.length">
                    <span class="indicator_span">{{ goods.goodsName }}:</span>
                    <span class="indicator_span">{{
                      computedQuotas(item.goodsCategoryList)
                    }}</span>
                  </div>
                </div>
              </el-tooltip>
            </div>
            <div class="origin f-hide2">
              <i class="hy-icon-shouhuodizhi"></i>
              {{ item.originPlace }}{{ item.originPlaceDetail }}
            </div>
          </div>
        </div>

        <div class="nodataImg" v-else>
          <img src="../../assets/zxx_img/nodata.png" alt="" />
        </div>
        <!-- @size-change="handleSizeChange" -->

        <el-pagination
          v-show="tableList.total > 0"
          @current-change="handleCurrentChange"
          :currentPage="searchData.page"
          :page-size="searchData.size"
          layout="total, prev, pager, next, jumper"
          :total="tableList.total"
          background
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import citys from "@/assets/json/citys";
import { reqGoodsList } from "@/api/hy";
export default {
  data() {
    return {
      citys: citys,
      searchData: {
        page: 1,
        size: 9,
        enterpriseName: "",
        goodsType: "",
        originPlace: "",
        releaseDateOrderBy: null,
        priceOrderBy: null,
        tradeStatus: "",
        selectTradeType: "", //1:挂牌,2:竞价
        selectOrderType: "", //1:销售,2:采购
        endFlag: false, //列表是否需要已结束的订单
        listingStatus: 0,
      },
      tableList: {
        list: [],
        total: 0,
      },
      searchDataOriginPlace: "",
      options: [
        {
          value: "0",
          label: "未开始",
        },
        {
          value: "1",
          label: "进行中",
        },
        {
          value: "3",
          label: "有中标",
        },
        {
          value: "2",
          label: "已流拍",
        },
      ],
      listingStatus: [
        {
          value: 0,
          label: "挂牌中",
        },
        {
          value: 1,
          label: "历史商品",
        },
      ],
    };
  },
  components: {
    Left: () => import("./components/left.vue"),
    CountDown: () => import("@/components/countDowns1.vue"),
  },
  watch: {
    $route: {
      handler() {
        // 在mounted函数执行的方法，放到该处
        this.searchData.page = 1;
        this.searchData.goodsType = "";
        this.getList();
      },
      deep: true,
    },
  },
  computed: {
    formatCount() {
      return (item) => {
        let count = item.goodsCategoryList.reduce((pre, cur, index) => {
          return (
            pre +
            (cur.number +
              "吨" +
              (index == item.goodsCategoryList.length - 1 ? "" : ","))
          );
        }, "");
        return count;
      };
    },
    computedQuotas() {
      return (val) => {
        if (val.length == 0) return "";
        let quotaValue = "";
        val.forEach((item) => {
          if (item.quotas?.length) {
            item.quotas.forEach((quota) => {
              quotaValue +=
                quota.name +
                quota.unit.split(";")[2] +
                quota.value +
                quota.unit.split(";")[1] +
                ",";
            });
          }
        });
        return quotaValue;
      };
    },
  },
  created() {},
  mounted() {
    this.searchData.selectTradeType = this.$route.query.selectTradeType;
    this.searchData.selectOrderType = this.$route.query.selectOrderType;
    this.getList();
  },
  filters: {
    priceCategoryFilter(val) {
      if (val.length > 1) return "详情查看价格信息";
      if (val.length === 0) return "";
      if (val[0].goodsPrice == 0) return "￥面议";
      return `${val[0].goodsPrice}元/吨`;
    },
    priceFilter(val) {
      if (+val == 0) return "￥面议";
      return `${val}元/吨`;
    },
    goodsStatus(val) {
      switch (val) {
        case 0:
          return "未开始";
          break;
        case 1:
          return "进行中";
          break;
        case 2:
          return "已流拍";
          break;
        case 3:
          return "有中标";
          break;
        case 4:
          return "已取消";
          break;
      }
    },
  },
  methods: {
    add() {
      // if (!this.beforhandle("all")) return;
      // {{ $route.query.selectTradeType == 1 ? "挂牌" : "竞价"
      //         }}{{ $route.query.selectOrderType == 1 ? "销售" : "采购" }}
      if (
        this.$route.query.selectTradeType == 1 &&
        this.$route.query.selectOrderType == 1
      ) {
        if (!this.beforhandle("listingSales:publish")) return;
      }
      if (
        this.$route.query.selectTradeType == 1 &&
        this.$route.query.selectOrderType == 2
      ) {
        if (!this.beforhandle("listingPurchase:publish")) return;
      }
      if (
        this.$route.query.selectTradeType == 2 &&
        this.$route.query.selectOrderType == 1
      ) {
        if (!this.beforhandle("auctionSale:publish")) return;
      }
      if (
        this.$route.query.selectTradeType == 2 &&
        this.$route.query.selectOrderType == 2
      ) {
        if (!this.beforhandle("biddingPurchase:publish")) return;
      }
      this.$router.push({
        path:
          this.$route.query.selectTradeType == 1
            ? "/addGoods"
            : "/addBiddingGoods",
        query: {
          selectOrderType: this.$route.query.selectOrderType,
          selectTradeType: this.$route.query.selectTradeType,
          type: this.$route.query.title,
          title: "发布" + "信息",
          // title: "发布" + this.$route.query.title + "信息",
        },
      });
    },
    detail(item) {
      this.$router.push({
        path: "/goodsDetail",
        query: {
          goodsId: item.goodsId,
          selectTradeType: this.$route.query.selectTradeType,
          selectOrderType: this.$route.query.selectOrderType,
          title: this.$route.query.title,
        },
      });
    },
    getList() {
      this.searchData.selectTradeType = this.$route.query.selectTradeType;
      this.searchData.selectOrderType = this.$route.query.selectOrderType;
      reqGoodsList(this.searchData)
        .then((ret) => {
          let list = ret.data.records;
          list.forEach((item) => {
            let a = [];

            if (item.quotas) {
              let quotas = item.quotas;
              quotas.forEach((items) => {
                a.push(
                  items.name +
                    items.unit.split(";")[2] +
                    items.value +
                    items.unit.split(";")[1]
                );
              });
            }
            item.title = a.toString();
          });
          if (ret.code == 0) {
            this.tableList.list = list;
            this.tableList.total = ret.data.total;
          } else {
            this.tableList.list = [];
            this.tableList.total = 0;
          }
          console.log(this.tableList.list, "商品列表");
        })
        // eslint-disable-next-line no-unused-vars
        .catch((err) => {
          this.tableList.list = [];
          this.tableList.total = 0;
        });
    },
    // handleSizeChange(val) {
    //   this.searchData.size = val;
    //   this.getList();
    // },
    handleCurrentChange(val) {
      this.searchData.page = val;
      this.getList();
    },
    typeSearch(val) {
      this.searchData.page = 1;
      this.searchData.goodsType = val;
      this.getList();
    },
    searchFn() {
      this.searchData.page = 1;
      this.searchData.originPlace = this.searchData.originPlace.toString();
      this.getList();
    },
    resetFn() {
      this.searchData.page = 1;
      this.searchData.enterpriseName = "";
      this.searchData.originPlace = "";
      this.searchData.tradeStatus = "";
      this.searchData.listingStatus = 0;
      this.getList();
    },
    sort(tag) {
      this.searchData[tag] = !!this.searchData[tag] ? 0 : 1;
      let obj = {
        releaseDateOrderBy: "priceOrderBy",
        priceOrderBy: "releaseDateOrderBy",
      };
      this.searchData[obj[tag]] = null;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-link--inner {
  font-size: 14px;
  line-height: 50px;
  height: 50px;
}

.indicator_span {
}

.mall {
  width: 1195px;
  margin: 14px auto;
  display: flex;

  &::before,
  &::after {
    display: table;
    content: "";
  }
}

.aside {
  // min-height: 500px;
  height: calc(100vh - 146px);
  border: 1px solid #e5e5e5;
  background: white;
  width: 186px;
  flex-shrink: 0;
  overflow: hidden;

  &::v-deep {
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }

  .t {
    line-height: 1;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #ebebeb;
    background: #f5f5f5;
    padding: 16px 0;
    overflow: hidden;
  }
}

.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px;

  .form {
    display: flex;
    width: 100%;

    .el-cascader {
      margin-left: 10px;
      width: 350px;
    }

    .hy-btn {
      margin-left: 10px;
      width: 20%;
    }

    .cl-btn {
      width: 20%;
    }
  }
}

.sort-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 25px;
  border: 1px solid #ebebeb;
  border-left: none;
  border-right: none;
  background: #f7f7f7;

  .left {
    display: flex;

    .items {
      display: flex;
      align-items: center;
      cursor: pointer;

      .caret-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-left: 5px;
        font-size: 14px;

        .active {
          color: #2484d6;
        }
      }

      & + .items {
        margin-left: 20px;
      }
    }
  }
}

.content {
  min-height: 500px;
  border: 1px solid #ebebeb;
  margin-left: 10px;
  background: white;
  flex: 1;
}

.item_type {
  display: flex;
  justify-content: space-between;
}

.list {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 20px;
  padding-bottom: 0;

  .items {
    border: 1px solid #ebebeb;
    min-width: 276px;
    width: 304px;
    margin: 0 20px 20px 0;
    padding: 10px;
    cursor: pointer;
    // background: linear-gradient(-45deg, #00A0FF, #0A65FF);
    box-shadow: 0px 5px 20px 0px rgba(143, 170, 249, 0.47);
    border-radius: 10px;

    &:hover {
      background: linear-gradient(-45deg, #00a0ff, #0a65ff);
      color: white;
    }

    &:nth-child(3n) {
      margin-right: 0 !important;
    }

    &:hover {
      border: 1px solid #2084d6;
    }

    .item-title {
      font-size: 16px;
      font-weight: bold;
      padding: 10px 4px;
      border-bottom: 1px solid #e5e5e5;
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 13px 3px;

      p {
        // width: 150px;
      }

      .price {
        flex-shrink: 0;
      }
    }

    .origin {
      margin-top: 20px;
    }
  }
}

.el-pagination {
  text-align: right;
  margin: 0 20px 20px 0;
}

.f-hide {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.f-hide2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.no-price,
.price {
  color: #fe7b35;
  // font-size: 18px;
}

.info-left {
  .f-hide {
    width: 120px;
    & + .f-hide {
      margin-top: 5px;
    }
  }
}

.indicator {
  // padding: 0 10px;
  // display: flex;
}

.nodataImg {
  padding: 200px 0;
  text-align: center;
}
.imag_dao {
  display: flex;
  align-items: center;
}
</style>
